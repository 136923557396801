<template>
  <div>
    <v-row justify="center">
      <div v-if="dialog && permission">
        <v-dialog
          :value="dialog"
          max-width="50%"
          persistent
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar>
              <v-btn icon @click="$emit('onCloseDialog')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="text-uppercase">
                {{
                  !permission._id
                    ? $t("title.create_permission")
                    : $t("title.update_permission")
                }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn :disabled="loading" text @click="validate">
                  {{
                    !permission._id ? $t("button.create") : $t("button.update")
                  }}
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="permission.permission_name"
                        :rules="rules.permission_name"
                        :label="$t('input.permission_name')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="permission.descr"
                        :rules="rules.descr"
                        :label="$t('input.description')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="permission.order"
                        :rules="rules.order"
                        :label="$t('input.order')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          permission_name: [(v) => !!v || "Name is required"],
          descr: [(v) => !!v || "description is required"],
          order: [(v) => !!v || "Order is required"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    permission: {
      type: Object,
      default: function () {
        return {
          permission_name: null,
          descr: null,
          order: null,
        };
      },
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("submit");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
